import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { isMobile } from 'react-device-detect';
import Button from 'antd/lib/button';
import {
  JOBSEEKER_PLAYSTORE_URL,
  JOBSEEKER_REGISTER_URL,
} from '@helpers/constants';

const MobileAppAd = ({ className = '' }) => {
  const data = useStaticQuery(graphql`
    {
      desktopPreview: file(relativePath: { eq: "wan-png-desktop.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            width: 754
            height: 330
            quality: 100
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const handleCreateAccountRedirection = () => {
    if (typeof window === 'undefined') {
      return;
    }

    if (isMobile) {
      window.location.assign(JOBSEEKER_PLAYSTORE_URL);
      return;
    }

    window.location.assign(JOBSEEKER_REGISTER_URL);
  };

  return (
    <section
      id="professional-profile"
      className={`professional-profile-container ${className}`}
    >
      <div className="professional-profile">
        <div className="header">
          Build your professional profile with Wan&nbsp;PNG
        </div>

        <div className="description">
          Work with the best companies hiring in Papua New Guinea.
        </div>

        <div className="create-account-button-container">
          <Button
            type="primary"
            className="create-account-button"
            onClick={handleCreateAccountRedirection}
          >
            Create an account
          </Button>
        </div>

        <div className="desktop-preview-container">
          <div className="desktop-preview">
            <GatsbyImage
              image={data?.desktopPreview?.childImageSharp?.gatsbyImageData}
              alt="Desktop Preview"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileAppAd;
