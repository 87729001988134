import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '@components/Layout/LayoutTemplates/JobSeekersLayout';
import SEO from '@components/SEO';
import HeroImage from './HeroImage';
import Benefits from './Benefits';
import PlatformPartners from '@components/PlatformPartners';
import OfficialNewsMediaPartners from '@components/OfficialNewsMediaPartners';
import CorporateSupporters from '@components/CorporateSupporters';
import CareerAdviceCTA from './CareerAdviceCTA';
import LatestNews from './LatestNews';
import UpcomingScheduledCourses from './UpcomingScheduledCourses';
import AboutUs from '@components/AboutUs';
import GetFullAccessToast from '@components/GetFullAccessToast';
import MobileAppAd from './MobileAppAd';
import ProfessionalProfile from './ProfessionalProfile';

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]');
}

const Home = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "jobseekers.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            transformOptions: { fit: COVER }
            layout: FULL_WIDTH
          )
        }
      }
    }
  `);

  return (
    <Layout isMobileComingSoon={false}>
      <SEO title="Home" description="Home" />
      <div className="home-section">
        <GetFullAccessToast />
        <HeroImage
          imgSrc={data?.file}
          header={'Success starts here.'}
          subHeader={'Find opportunities through Wan PNG.'}
        />
        <Benefits
          header={'Wan PNG supports your journey to greater success.'}
          infos={[
            {
              xlinkHref: '#job-icon',
              title: 'Employment opportunities',
              description:
                'Gain experience with internships, start earning with entry positions, or upgrade your career with the next big job.',
            },
            {
              xlinkHref: '#hand-icon',
              title: 'Career advice',
              description:
                'Advance your career with expert advice and professional resources, and stay up-to-date on the latest news.',
            },
            {
              xlinkHref: '#school',
              title: 'Education and training',
              description:
                'Expand your employment prospects by upgrading your knowledge, skills and competencies through courses.',
            },
          ]}
        />
        <MobileAppAd className={'mobile'} />
        <ProfessionalProfile />
        <CareerAdviceCTA />
        <UpcomingScheduledCourses />
        <LatestNews />
        <MobileAppAd className={'desktop'} />
        <AboutUs offSection />
        <PlatformPartners onSection />
        <OfficialNewsMediaPartners onSection />
        <CorporateSupporters onSection />
      </div>
    </Layout>
  );
};

export default Home;
