import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { JOBSEEKER_PLAYSTORE_URL } from '@helpers/constants';

const MobileAppAd = ({ className = '' }) => {
  const data = useStaticQuery(graphql`
    {
      mobilePreview: file(relativePath: { eq: "wan-png-mobile-app.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            width: 600
            height: 600
            quality: 100
            layout: CONSTRAINED
          )
        }
      }

      googlePlayBadge: file(relativePath: { eq: "google-play-badge.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            width: 170
            height: 60
            quality: 100
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const handleGooglePlayRedirection = () => {
    window.location.assign(JOBSEEKER_PLAYSTORE_URL);
  };

  return (
    <section
      id="mobile-app-ad"
      className={`mobile-app-ad-container ${className}`}
    >
      <div className="mobile-app-ad">
        <div className="app-preview">
          <GatsbyImage
            image={data?.mobilePreview?.childImageSharp?.gatsbyImageData}
            alt="App Preview"
          />
        </div>

        <div className="group-1">
          <div className="header">Download the Wan&nbsp;PNG mobile app.</div>

          <div className="group-2">
            <div className="label-badge-group">
              <p className="download-from">
                Download the Wan PNG mobile app from:
              </p>
              <div
                className="google-play-badge"
                onClick={handleGooglePlayRedirection}
              >
                <GatsbyImage
                  image={
                    data?.googlePlayBadge?.childImageSharp?.gatsbyImageData
                  }
                  alt="Google Play Badge"
                />
              </div>
            </div>

            <div className="description">
              {
                'The Wan PNG Job Seekers Mobile app lets you create an account on Wan PNG and build your professional profile. Showcase your skills & competencies, select your job preferences, and choose a professional CV template to automatically create your ideal online CV.'
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileAppAd;
