import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Card from '@components/Cards/ArticleCard';
import ArrowButton from '@components/ArrowButton';
import isEmpty from 'lodash/isEmpty';
import DataWrapper from '@components/DataWrapper';
import EmptyBar from '@components/EmptyState/EmptyBar';
import getNewsCardsProps from '@helpers/methods/modifyContents/getNewsCardsProps';

const LatestNews = () => {
  const { allStrapiNewsArticles } = useStaticQuery(
    graphql`
      {
        allStrapiNewsArticles(
          limit: 2
          sort: { fields: created_at, order: DESC }
        ) {
          nodes {
            strapiId
            title
            slug
            category: news_category {
              name
            }
            created_at(formatString: "MMM DD, YYYY")
            author: created_by {
              username
            }

            thumbnail_image {
              source_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                  }
                }
              }
              cropped_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                  }
                }
              }
            }
            thumbnail_image_old: image_thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, quality: 100)
                }
              }
            }
          }
        }
      }
    `
  );

  const allNewsArticles = getNewsCardsProps(allStrapiNewsArticles?.nodes);
  const hasNoContent = isEmpty(allNewsArticles);

  return (
    <DataWrapper isEmpty={hasNoContent} empty={<EmptyBar />}>
      <section className="latest-news-container">
        <div className="latest-news">
          <span className="heading _section-label">Latest news</span>

          <div className="card-button-group">
            <div className="card-wrapper">
              {allNewsArticles.map((item) => {
                const slug = item?.slug;
                const link = `/news/${slug}`;
                return <Card data={item} key={item?.strapiId} link={link} />;
              })}
            </div>

            <ArrowButton label={'More from our news'} link={'/news'} />
          </div>
        </div>
      </section>
    </DataWrapper>
  );
};

export default LatestNews;
