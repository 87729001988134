import React from 'react';
import { Link } from 'gatsby';
import Button from 'antd/lib/button';
import classNames from 'classnames';

const CareerAdviceCTA = ({ offSection = false }) => {
  return (
    <section
      className={classNames('career-advice-cta-container', {
        '_bg-light-gray': offSection,
      })}
    >
      <div className="career-advice-cta">
        <div className="title">
          <span className="first-line">Advice that's right for you.</span>
        </div>
        <div className="_description-label">
          Expert career tips and advice, courses and trainings, and helpful
          resources.
        </div>
        <Link to="/career-development" className="link-btn">
          <Button type="primary" className="primary-btn">
            Explore Career Development
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default CareerAdviceCTA;
